import { Stack } from "@mui/material";
import { TFunction } from "i18next";
import Tooltip from "../../../components/Tooltip";
import { translationKeys } from "../../../translations/main-translations";
import GenerationChip from "../levels/GenerationChip";

interface IGenerationSelectorProps {
    visibleCalculationIdentifiers: string[];
    updateVisibleCalculationIdentifiers: (newLevels: string[]) => void;
    calculationIdentifiers: string[];
    translate: TFunction;
    disabled?: boolean;
}

const GenerationSelector = ({
    visibleCalculationIdentifiers,
    calculationIdentifiers,
    updateVisibleCalculationIdentifiers,
    translate,
    disabled = false,
}: IGenerationSelectorProps) => {
    const makeUpdateCalculationIdentifier = (calculationIdentifier: string) => () => {
        const newLevels = visibleCalculationIdentifiers.includes(calculationIdentifier)
            ? visibleCalculationIdentifiers.filter((l) => l !== calculationIdentifier)
            : [...visibleCalculationIdentifiers, calculationIdentifier];
        updateVisibleCalculationIdentifiers(newLevels);
    };

    const tooltips: any = calculationIdentifiers.reduce((all, name) => {
        const chipState = !visibleCalculationIdentifiers.includes(name)
            ? "inactive"
            : visibleCalculationIdentifiers.length === 1
              ? "disabled"
              : "active";
        return {
            ...all,
            [name]: translate(`${translationKeys.VDLANG_CALCULATION_TABLE_TOOLTIP}.chip_state_${chipState}`, {
                generation: translate(`${translationKeys.VDLANG_CALCULATION_IDENTIFIER}.${name}`),
            }),
        };
    }, {});

    return (
        <Stack direction="row" p={1} spacing={0.5}>
            {calculationIdentifiers.map((name) => (
                <Tooltip key={name} title={tooltips[name]}>
                    <GenerationChip
                        disabled={disabled || (visibleCalculationIdentifiers.length === 1 && visibleCalculationIdentifiers[0] === name)}
                        label={translate(`${translationKeys.VDLANG_CALCULATION_IDENTIFIER}.${name}`)}
                        variant={visibleCalculationIdentifiers.includes(name) ? "active" : undefined}
                        onClick={makeUpdateCalculationIdentifier(name)}
                    />
                </Tooltip>
            ))}
        </Stack>
    );
};

export default GenerationSelector;
