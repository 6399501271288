import {
    CalculationType,
    CurrencyDto,
    EffectCategoryAttributeDto,
    EffectCategoryDto,
    EffectFilterCurrencyField,
    EffectSeriesDto,
    EffectType,
    GateTaskConfigDto,
    GenerationDto,
    MeasureConfigDto,
    mergeCamelized,
} from "api-shared";
import classNames from "classnames";
import type { IColumnProps } from "devextreme-react/data-grid";
import { TFunction } from "i18next";
import { findIndex, findLastIndex, groupBy, sortBy } from "lodash";
import useFieldData from "../../../hooks/useFieldData";
import { TranslatedOption } from "../../../lib/field-options";
import { translationKeys } from "../../../translations/main-translations";
import { ExtendedEffectSeries } from "./CalculationTable";

// In devextreme v23, typing was made stricter so that e.g. `ownerBand` must now be a number or undefined
// Hence we must use this transitional interface until we implement a different solution for deriving the final index for `ownerBand`
interface IColumnPropsCompat extends Omit<IColumnProps, "ownerBand"> {
    ownerBand?: string | number;
}

const effectColumnOrder = [EffectFilterCurrencyField.Initial, EffectFilterCurrencyField.Effect, EffectFilterCurrencyField.PriceHike];

enum ColumnNames {
    EffectCategoryAttributePrefix = "effect_category_attribute",
    EffectCategoryValuePrefix = "effect_category_value",
    GenerationPrefix = "generation",
    EffectCategoryCurrency = "effect_category_currency",
    TotalTop = "total_top",
    TotalCurrency = "total_currency",
    TotalPrefix = "total",
    TotalFiller = "total_filler",
    LabelGeneration = "label_generation",
    LabelType = "label_typevalue",
}

export class EffectColumnIdentifier {
    private static PARSE_REGEX = /EC(?<effectCategoryId>\d+)_G(?<generationId>\d+)_(?<type>\w+)/;

    constructor(
        public readonly generationId: number,
        public readonly effectCategoryId: number,
        public readonly type: EffectFilterCurrencyField,
    ) {}

    static fromString(input: string): EffectColumnIdentifier {
        const match = input.match(this.PARSE_REGEX);
        if (match === null || match.groups === undefined) {
            throw new Error(`Parsing of column identifier '${input}' failed`);
        }

        const { effectCategoryId, generationId, type } = match.groups;
        if (!Object.values(EffectFilterCurrencyField).includes(type as any)) {
            throw new Error(`Parsing of column identifier '${input}' failed`);
        }
        return new EffectColumnIdentifier(+generationId, +effectCategoryId, type as EffectFilterCurrencyField);
    }

    toString(): string {
        return `EC${this.effectCategoryId}_G${this.generationId}_${this.type}`;
    }
}

const getFieldLabel = (fieldName: string, translate: TFunction, processName: string) => {
    const keys = [mergeCamelized(fieldName, processName), fieldName];
    return translate(keys);
};

function getLabelColumns(
    categoryFields: EffectCategoryAttributeDto[],
    minWidth: number,
    translate: TFunction,
    processName: string,
    emptyCellClass: string,
): IColumnPropsCompat[] {
    const fieldColumns = categoryFields.map(({ title }, index, arr) => ({
        name: `${ColumnNames.EffectCategoryAttributePrefix}_${title}`,
        caption: getFieldLabel(title, translate, processName),
        ownerBand: index > 0 ? `${ColumnNames.EffectCategoryAttributePrefix}_${arr[index - 1].title}` : undefined,
        isBand: true,
    }));

    const currencyColumn = {
        name: ColumnNames.EffectCategoryCurrency,
        caption: translate("Currency"),
        ownerBand: fieldColumns[fieldColumns.length - 1]?.name,
        isBand: true,
    };

    const generationColumn = {
        name: ColumnNames.LabelGeneration,
        caption: "",
        isBand: true,
        ownerBand: currencyColumn.name,
        cssClass: emptyCellClass,
    };

    const lowestColumn = {
        name: ColumnNames.LabelType,
        dataField: "fiscalMoment",
        caption: translate(translationKeys.VDLANG_GENERATIONS_LABEL_COLUMN_HEADER),
        ownerBand: generationColumn.name,
        minWidth,
    };
    return [...fieldColumns, currencyColumn, generationColumn, lowestColumn];
}

// eslint-disable-next-line max-params
function getEffectCategoryGenerationColumns(
    byCategory: Record<number, EffectColumnIdentifier[]>,
    generationGateTaskConfigs: Record<number, GateTaskConfigDto | undefined>,
    generations: GenerationDto[],
    translate: TFunction,
    firstOfCategoryClass: string,
    lastOfCategoryClass: string,
    lockedCalculationIdentifiers: string[],
    userCanEdit: boolean,
): IColumnPropsCompat[] {
    return Object.entries(byCategory).flatMap(([categoryId, identifiers]) => {
        const byGeneration = groupBy(identifiers, (identifier) => identifier.generationId);
        const typeColumns = Object.keys(byGeneration)
            .map((id) => +id)
            .map((generationId) => {
                const gateTaskConfig = generationGateTaskConfigs[generationId];
                const generation = generations.find((generation) => generation.id === generationId);
                const isNonLinear = generation?.calculationType === CalculationType.NonLinear;
                return {
                    generationId,
                    name: `${ColumnNames.GenerationPrefix}_${generationId}`,
                    caption:
                        gateTaskConfig?.calculationIdentifier != null
                            ? translate(`${translationKeys.VDLANG_CALCULATION_IDENTIFIER}.${gateTaskConfig.calculationIdentifier}`)
                            : undefined,
                    ownerBand: `${ColumnNames.EffectCategoryCurrency}_${categoryId}`,
                    gateTaskConfigOrder: gateTaskConfig?.order,
                    isNonLinear,
                    isBand: true,
                    allowEditing: userCanEdit && !lockedCalculationIdentifiers.includes(gateTaskConfig?.calculationIdentifier ?? ""),
                } as IColumnPropsCompat;
            });
        const orderedTypeColumns = sortBy(typeColumns, "gateTaskConfigOrder");
        // add attributes that helps to determine if copy button can be shown
        // ColumnProps come from devexpress and cannot be extended, so use any here
        orderedTypeColumns.forEach((orderedTypeColumn: any, index) => {
            orderedTypeColumn.copyEnabled = index > 0;
            if (index > 0) {
                orderedTypeColumn.prevCaption = orderedTypeColumns[index - 1].caption;
            }
        });
        orderedTypeColumns[0].cssClass = firstOfCategoryClass;
        orderedTypeColumns[orderedTypeColumns.length - 1].cssClass = lastOfCategoryClass;
        return orderedTypeColumns;
    });
}

// eslint-disable-next-line max-params
function getEffectCategoryValueColumns(
    effectCategories: EffectCategoryDto[],
    byCategory: Record<number, EffectColumnIdentifier[]>,
    sortedCategoryFields: EffectCategoryAttributeDto[],
    fieldData: TranslatedOption[][],
    firstOfCategoryClass: string,
    lastOfCategoryClass: string,
    lockedCalculationIdentifiers: string[],
    userCanEdit: boolean,
): IColumnPropsCompat[] {
    return effectCategories
        .filter((ec) => byCategory[ec.id] != null)
        .flatMap(({ id: ecId, effectCategoryValues }) => {
            const orderedValues = sortBy(effectCategoryValues, (value) =>
                sortedCategoryFields.findIndex(({ id }) => id === value.effectCategoryAttributeId),
            );
            return orderedValues.map((ecv, index, arr) => ({
                name: index < arr.length - 1 ? `${ColumnNames.EffectCategoryValuePrefix}_${ecv.id}` : `EC${ecId}_last_value`,
                ownerBand: index > 0 ? `${ColumnNames.EffectCategoryValuePrefix}_${arr[index - 1].id}` : undefined,
                caption: fieldData[index].find((option) => option.id === ecv.value)?.name,
                cssClass: classNames(firstOfCategoryClass, lastOfCategoryClass),
                isBand: true,
                effectCategoryId: ecId,
                allowEditing: userCanEdit && lockedCalculationIdentifiers.length === 0,
            }));
        });
}

function getCurrencyColumns(
    effectCategories: EffectCategoryDto[],
    lastOfCategoryClass: string,
    lockedCalculationIdentifiers: string[],
    userCanEdit: boolean,
): IColumnPropsCompat[] {
    return effectCategories.map((ec) => ({
        name: `${ColumnNames.EffectCategoryCurrency}_${ec.id}`,
        caption: ec.currency?.isoCode,
        isBand: true,
        ownerBand: `EC${ec.id}_last_value`,
        cssClass: lastOfCategoryClass,
        allowEditing: userCanEdit && lockedCalculationIdentifiers.length === 0,
    }));
}

function getEffectColumnIdentifiers(effectSeries: EffectSeriesDto[], orderedGenerations: GenerationDto[]): EffectColumnIdentifier[] {
    const identifiers = effectSeries.map(
        ({ effectCategoryId, generationId, type }) => new EffectColumnIdentifier(generationId, effectCategoryId, type),
    );
    return sortBy(identifiers, [
        "effectCategoryId",
        ({ generationId }) => orderedGenerations.findIndex(({ id }) => id === generationId),
        (i) => effectColumnOrder.indexOf(i.type),
    ]);
}

// eslint-disable-next-line max-params
function getEffectColumns(
    identifiers: EffectColumnIdentifier[],
    effectCategories: EffectCategoryDto[],
    minWidth: number,
    translate: TFunction,
    classes: Record<"firstOfCategory" | "lastOfCategory" | "lastOfGeneration", string>,
    generationGateTaskConfigs: Record<number, GateTaskConfigDto | undefined>,
    lockedCalculationIdentifiers: string[],
    userCanEdit: boolean,
): IColumnPropsCompat[] {
    return identifiers.map((identifier, index, arr) => {
        const effectType = effectCategories.find(({ id }) => id === identifier.effectCategoryId)?.effectType;
        const captionKey =
            identifier.type === EffectFilterCurrencyField.Effect ? `effect_type_${effectType ?? EffectType.Savings}` : identifier.type;

        const firstOfCategoryIndex = findIndex(identifiers, { effectCategoryId: identifier.effectCategoryId });
        const lastOfCategoryIndex = findLastIndex(identifiers, { effectCategoryId: identifier.effectCategoryId });
        const categoryColumnCount = lastOfCategoryIndex - firstOfCategoryIndex + 1;

        const config = generationGateTaskConfigs[identifier.generationId];

        return {
            name: identifier.toString(),
            dataField: identifier.toString(),
            caption: translate(captionKey),
            ownerBand: `${ColumnNames.GenerationPrefix}_${identifier.generationId}`,
            minWidth: minWidth / categoryColumnCount, // make sure that category columns have given min-width
            cssClass: classNames({
                [classes.firstOfCategory]: firstOfCategoryIndex === index,
                [classes.lastOfCategory]: lastOfCategoryIndex === index,
                [classes.lastOfGeneration]: arr[index + 1]?.generationId !== identifier.generationId,
            }),

            allowEditing: userCanEdit && !lockedCalculationIdentifiers.includes(config?.calculationIdentifier ?? ""),
        };
    });
}

function getSumColumns(
    visibleCalculationIdentifiers: string[],
    translate: TFunction,
    fieldCount: number,
    emptyCellClass: string,
    currency: CurrencyDto,
): IColumnPropsCompat[] {
    // Filler columns are added for fieldCount > 1. Css styling and parent child behavior of the other
    // columns differ based on the presence of these filler columns.
    const hasFillerColumns = fieldCount > 1;

    const topColumn = {
        name: ColumnNames.TotalTop,
        isBand: true,
        caption: translate(translationKeys.VDLANG_MEASURE_CALCULATION_TOTAL_COLUMN_HEADER),
        cssClass: hasFillerColumns ? emptyCellClass : undefined,
    };

    // Add some (empty) cells on top to ensure proper alignment of bottom rows with dynamic amount of effectCategoryFields
    const fillerColumns = [...Array(fieldCount - 1)].map((_, i, arr) => ({
        caption: "",
        name: `${ColumnNames.TotalFiller}_${i}`,
        ownerBand: i > 0 ? `${ColumnNames.TotalFiller}_${i - 1}` : topColumn.name,
        cssClass: i < arr.length - 1 ? emptyCellClass : undefined, // do not apply empty cell styles on the last filler cell
        isBand: true,
    }));

    const currencyColumn = {
        name: ColumnNames.TotalCurrency,
        ownerBand: hasFillerColumns ? fillerColumns[fillerColumns.length - 1]?.name : topColumn.name,
        isBand: true,
        caption: currency.isoCode,
    };

    const generationColumns = visibleCalculationIdentifiers.flatMap((calculationIdentifier) => [
        {
            // generation
            name: `${ColumnNames.TotalPrefix}_${calculationIdentifier}`,
            caption: translate(`${translationKeys.VDLANG_CALCULATION_IDENTIFIER}.${calculationIdentifier}`),
            isBand: true,
            ownerBand: currencyColumn.name,
        },
        {
            // below generation
            caption: translate(EffectFilterCurrencyField.Effect),
            name: `${ColumnNames.TotalPrefix}_${calculationIdentifier}_${EffectFilterCurrencyField.Effect}`,
            dataField: `${ColumnNames.TotalPrefix}_${calculationIdentifier}_${EffectFilterCurrencyField.Effect}`,
            ownerBand: `${ColumnNames.TotalPrefix}_${calculationIdentifier}`,
        },
    ]);

    return [topColumn, currencyColumn, ...fillerColumns, ...generationColumns];
}

function customizeColumns(columns: IColumnPropsCompat[]): void {
    // in-place edit the provided column array
    columns.forEach((column) => {
        // ownerBand needs to be the index of the parent column, but is provided as string (the columns name)
        // resolve the parent column name to its final index here
        if (typeof column.ownerBand !== "string") {
            return column;
        }
        const parentColumn = columns.findIndex(({ name }) => name === column.ownerBand);
        if (parentColumn > -1) {
            column.ownerBand = parentColumn;
        }
    });
}

interface IUseCalculationTableColumnsProps {
    effectSeries: ExtendedEffectSeries[];
    translate: TFunction;
    measureConfig: MeasureConfigDto;
    effectCategoryAttributes: EffectCategoryAttributeDto[];
    effectCategories: EffectCategoryDto[];
    generations: GenerationDto[];
    classes: Record<"firstOfCategory" | "lastOfCategory" | "lastOfGeneration" | "emptyCell", string>;
    minWidth: number;
    generationGateTaskConfigs: Record<number, GateTaskConfigDto | undefined>;
    visibleCalculationIdentifiers: string[];
    summaryCurrency: CurrencyDto;
    lockedCalculationIdentifiers: string[];
    userCanEdit: boolean;
}

const useCalculationTableColumns = ({
    measureConfig,
    effectCategoryAttributes,
    effectSeries,
    effectCategories,
    generationGateTaskConfigs,
    generations,
    translate,
    classes,
    visibleCalculationIdentifiers,
    minWidth,
    summaryCurrency,
    lockedCalculationIdentifiers,
    userCanEdit,
}: IUseCalculationTableColumnsProps) => {
    // Don't use measureConfig.effectCategoryAttributes here
    const { name: processName } = measureConfig;
    // basic data required for multiple columns
    const orderedGenerations = sortBy(generations, (generation) => generationGateTaskConfigs[generation.id]?.order);
    const effectColumnIdentifiers = getEffectColumnIdentifiers(effectSeries, orderedGenerations);
    const byCategory = groupBy(effectColumnIdentifiers, (identifier) => identifier.effectCategoryId);

    // Columns for EffectCategories
    const sortedCategoryFields = [...effectCategoryAttributes].sort((a, b) =>
        a.order !== null && b.order !== null ? a.order - b.order : 0,
    );
    const fieldData = useFieldData(sortedCategoryFields);
    const categoryColumns = getEffectCategoryValueColumns(
        effectCategories,
        byCategory,
        sortedCategoryFields,
        fieldData,
        classes.firstOfCategory,
        classes.lastOfCategory,
        lockedCalculationIdentifiers,
        userCanEdit,
    );

    const currencyColumns = getCurrencyColumns(effectCategories, classes.lastOfCategory, lockedCalculationIdentifiers, userCanEdit);

    const generationColumns = getEffectCategoryGenerationColumns(
        byCategory,
        generationGateTaskConfigs,
        generations,
        translate,
        classes.firstOfCategory,
        classes.lastOfCategory,
        lockedCalculationIdentifiers,
        userCanEdit,
    );

    const effectColumns = getEffectColumns(
        effectColumnIdentifiers,
        effectCategories,
        minWidth,
        translate,
        classes,
        generationGateTaskConfigs,
        lockedCalculationIdentifiers,
        userCanEdit,
    );

    // Other columns
    const sumColumns = getSumColumns(
        visibleCalculationIdentifiers,
        translate,
        effectCategoryAttributes.length,
        classes.emptyCell,
        summaryCurrency,
    );

    const labelColumns = getLabelColumns(sortedCategoryFields, minWidth, translate, processName, classes.emptyCell);

    // make assumption about column ordering here
    // It enables passing the correct column definitions to the table and avoids a "second rendering pass" using
    // customizeColumns callback
    const orderedColumns = [...labelColumns, ...categoryColumns, ...currencyColumns, ...generationColumns, ...effectColumns, ...sumColumns];
    customizeColumns(orderedColumns);

    return {
        effectColumns: effectColumns as IColumnProps[],
        categoryColumns: categoryColumns as IColumnProps[],
        generationColumns: generationColumns as IColumnProps[],
        sumColumns: sumColumns as IColumnProps[],
        labelColumns: labelColumns as IColumnProps[],
        currencyCols: currencyColumns as IColumnProps[],
    };
};

export default useCalculationTableColumns;
