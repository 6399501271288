import {
    EffectCategoryDto,
    EffectFilterCurrencyField,
    EffectSeriesDto,
    EffectSeriesUpdateDto,
    EffectType,
    GenerationDto,
} from "api-shared";
import { keyBy } from "lodash";
import { useCallback, useMemo } from "react";

interface IUseSignedEffectsProps {
    effectCategories: EffectCategoryDto[];
    effectSeries: EffectSeriesDto[];
    generations: GenerationDto[];
    updateEffectSeries: (changes: EffectSeriesUpdateDto) => void;
}

const useSignedEffects = ({ effectSeries, generations, effectCategories, updateEffectSeries }: IUseSignedEffectsProps) => {
    const effectTypeByGeneration = useMemo(() => {
        const ecById = keyBy(effectCategories, "id");
        return generations.reduce(
            (acc, generation) => ({ ...acc, [generation.id]: ecById[generation.effectCategoryId]?.effectType }),
            {} as Record<number, EffectType | undefined>,
        );
    }, [effectCategories, generations]);

    const signedEffectSeries = useMemo(
        () =>
            effectSeries.map((es) => {
                const effectType = effectTypeByGeneration[es.generationId];

                if (es.type !== EffectFilterCurrencyField.Effect || effectType !== EffectType.ChangeoverCosts) {
                    return es;
                }
                return {
                    ...es,
                    effects: es.effects.map(({ value, inputValue, ...e }) => ({ ...e, value: -value, inputValue: -inputValue })),
                };
            }),
        [effectSeries, effectTypeByGeneration],
    );

    const updateSignedEffectSeries = useCallback(
        (changes: EffectSeriesUpdateDto) => {
            const { generationId, value, type } = changes;
            const effectType = effectTypeByGeneration[generationId];

            const signedChanges = { ...changes };
            if (type === EffectFilterCurrencyField.Effect && effectType === EffectType.ChangeoverCosts && value != null) {
                signedChanges.value = -value;
            }

            updateEffectSeries(signedChanges);
        },
        [updateEffectSeries, effectTypeByGeneration],
    );

    return [signedEffectSeries, updateSignedEffectSeries] as const;
};

export default useSignedEffects;
