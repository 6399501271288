import { activateDevextremeLicense } from "../../../lib/licenses";
// activate license before anything else from devextreme is imported
// This does only change the bundle by a few hundreds of bytes, so its ok to have this not in the regular devextreme bundle
activateDevextremeLicense();

import { Box, Divider, Grid, Paper } from "@mui/material";
import {
    EffectDto,
    EffectSeriesDeleteDto,
    EffectSeriesUpdateDto,
    GateTaskType,
    MeasureCalculationGranularity,
    nonNullable,
} from "api-shared";
import moment, { Moment } from "moment";
import React, { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import OptionalDialog from "../../../components/OptionalDialog";
import DeleteDialog from "../../../components/dialogues/DeleteDialog";
import LoadingAnimation from "../../../components/loading/LoadingAnimation";
import { useClientFiscalYear, useCurrentClient } from "../../../domain/client";
import { useDeleteProcessEffects, useProcessEffects, useUpdateProcessEffects } from "../../../domain/effect";
import { useEffectCategories } from "../../../domain/effect-category";
import { useGenerations, useUpdateGeneration } from "../../../domain/generation";
import { useEffectCategoryAttributesForMeasure, useGateTaskConfigs } from "../../../domain/measure-config";
import { useCurrentUserCanEditMeasure, useUpdateMeasure } from "../../../domain/measure/detail";
import useDialog from "../../../hooks/useDialog";
import useFieldData from "../../../hooks/useFieldData";
import { useLanguage } from "../../../hooks/useLanguage";
import {
    getEffectCategoryCurrenciesInUse,
    getEffectCategoryEffectTypesInUse,
    getEffectCategoryValuesInUse,
} from "../../../lib/effect-category";
import { IntervalFromGranularity } from "../../../lib/fiscal-units";
import useCurrencyContext, { CurrencyContextProvider } from "../../CurrencyContext";
import { useMeasureContext } from "../../MeasureContext";
import AddEffectCategoryButtons from "../AddEffectCategoryButtons";
import CalculatorImage from "../CalculatorImage";
import EffectCategoryDialog from "../levels/EffectCategoryDialog";
import CalculationHistoryDialog from "../levels/effect-category/CalculationHistoryDialog";
import { useCalculation } from "../levels/effect-category/useCalculation";
import CalculationTable from "./CalculationTable";
import ExtendedCalculationHeader from "./ExtendedCalculationHeader";

export function getEffectTimeRange(effects: EffectDto[]): { effectStart: Moment | null; effectEnd: Moment | null } {
    if (effects.length === 0) {
        return { effectStart: null, effectEnd: null };
    }
    const earliestEffect = effects.reduce((acc, e) => (e.year < acc.year || (e.year === acc.year && e.month < acc.month) ? e : acc));
    const latestEffect = effects.reduce((acc, e) => (e.year > acc.year || (e.year === acc.year && e.month > acc.month) ? e : acc));
    const effectStart = moment().month(earliestEffect.month).year(earliestEffect.year);
    const effectEnd = moment().month(latestEffect.month).year(latestEffect.year);
    return { effectStart, effectEnd };
}

const ExtendedCalculation = () => {
    const [categoryToShowHistory, setCategoryToShowHistory] = useState<number | null>(null);

    const measure = useMeasureContext();
    const currentUserCanEditMeasure = useCurrentUserCanEditMeasure(measure);
    const effectCategoryFields = useEffectCategoryAttributesForMeasure(measure);

    const effects = useProcessEffects(measure.id);
    const updateEffectsMutation = useUpdateProcessEffects().mutate;
    const deleteEffectsMutation = useDeleteProcessEffects().mutate;
    const allGenerations = useGenerations(measure.id);
    const updateGeneration = useUpdateGeneration().mutate;
    const client = useCurrentClient();
    const fiscalYearStart = useClientFiscalYear();
    const effectCategories = useEffectCategories(measure.id);

    const updateMeasureMutation = useUpdateMeasure();

    const { isOpen: isFullscreen, closeDialog: closeFullscreen, toggleDialog: toggleFullscreen } = useDialog();

    const { t: translate } = useTranslation();
    const lang = useLanguage();

    const allEffectCategoryValues = useFieldData(effectCategoryFields);

    // custom* contain calendar moments, not fiscal ones
    const [customStart, setCustomStart] = useState<Moment | null>(null);
    const [customEnd, setCustomEnd] = useState<Moment | null>(null);

    const { effectStart, effectEnd } = useMemo(() => {
        const allEffects = effects.data?.flatMap((e) => e.effects) ?? [];
        return getEffectTimeRange(allEffects);
    }, [effects.data]);

    // make sure, start and end always include the effects
    const start = customStart != null && effectStart != null ? moment.min(customStart, effectStart) : (customStart ?? effectStart);
    const end = customEnd != null && effectEnd != null ? moment.max(customEnd, effectEnd) : (customEnd ?? effectEnd);

    const updateGranularity = (calculationGranularity: MeasureCalculationGranularity) =>
        updateMeasureMutation.mutate({ measureId: measure.id, changes: { calculationGranularity } });

    const orderedCalculationGateTaskConfigs = [...measure.measureConfig.gateTaskConfigs]
        .filter((gtc) => gtc.type === GateTaskType.Calculation && gtc.calculationIdentifier !== null)
        .sort((a, b) => a.order - b.order);

    const firstCalculationGateTaskConfig = orderedCalculationGateTaskConfigs[0];

    const firstCalculationGateTask = measure.gateTasks.find((gateTask) => gateTask.gateTaskConfigId === firstCalculationGateTaskConfig?.id);

    const calculationIdentifiers = orderedCalculationGateTaskConfigs
        .map((gtc) => gtc.calculationIdentifier)
        .filter((a): a is string => a !== null);
    const [visibleCalculationIdentifiers, setVisibleCalculationIdentifiers] = useState(calculationIdentifiers);

    const updateVisibleCalculationIdentifiers = (newIdentifiers: string[]) => {
        // calculationIdentifiers are sorted, make sure order is preserved
        setVisibleCalculationIdentifiers(calculationIdentifiers.filter((ci) => newIdentifiers.includes(ci)));
    };

    const updateEffects = useCallback(
        (update: EffectSeriesUpdateDto) => {
            if (update.value !== null) {
                updateEffectsMutation({ ...update, measureId: measure.id });
            } else {
                const { value, ...deletePayload } = update;
                deleteEffectsMutation({ ...deletePayload, measureId: measure.id });
            }
        },
        [updateEffectsMutation, measure.id, deleteEffectsMutation],
    );

    const deleteEffects = useCallback(
        (deletePayload: EffectSeriesDeleteDto) => {
            deleteEffectsMutation({ ...deletePayload, measureId: measure.id });
        },
        [measure.id, deleteEffectsMutation],
    );

    const onAddTimeslice = useCallback(
        (location: "top" | "bottom") => {
            if (location === "top" && start != null) {
                const newStart = start.clone().add(-1, IntervalFromGranularity[measure.calculationGranularity]);
                setCustomStart(newStart);
            } else if (location === "bottom" && end != null) {
                const newEnd = end.clone().add(1, IntervalFromGranularity[measure.calculationGranularity]);
                setCustomEnd(newEnd);
            }
        },
        [measure.calculationGranularity, start, end],
    );

    const {
        categoryToEdit,
        setCategoryToEdit,
        addCategory,
        defaultEffectType,
        saveCategory,
        generations,
        categoryToDelete,
        setCategoryToDelete,
        removeEffectCategory,
        copyFields,
    } = useCalculation({
        measureId: measure.id,
        gateTaskId: firstCalculationGateTask?.id,
        allGenerations: allGenerations.data ?? [],
        updateGeneration,
    });
    const processCurrency = useCurrencyContext();

    const gateTaskConfigs = useGateTaskConfigs();

    if (!effectCategories.isSuccess) {
        return <LoadingAnimation />;
    }

    const hasEffectCategories = effectCategories.data.length > 0;
    const NoDataComponent = hasEffectCategories ? LoadingAnimation : CalculatorImage;

    const lockedGateTasks = measure.gateTasks.filter((gt) => gt.locked);

    const gateTaskConfigIdsOfLockedGateTasks = [...new Set(lockedGateTasks.map((gt) => gt.gateTaskConfigId))];
    const lockedCalculationIdentifiers = gateTaskConfigIdsOfLockedGateTasks
        .map((gtcId) => gateTaskConfigs.find((gtc) => gtc.id === gtcId))
        .map((gateTaskConfig) => gateTaskConfig?.calculationIdentifier)
        .filter(nonNullable);

    return (
        <Paper>
            <OptionalDialog fullScreen open={isFullscreen} onClose={closeFullscreen}>
                <div>
                    {firstCalculationGateTaskConfig != null && (
                        <EffectCategoryDialog
                            key={`${defaultEffectType}${Number(categoryToEdit)}`}
                            open={Boolean(categoryToEdit)}
                            onClose={() => setCategoryToEdit(null)}
                            translate={translate}
                            processName={measure.measureConfig.name}
                            client={client}
                            lang={lang}
                            effectCategory={effectCategories.data.find(({ id }) => id === categoryToEdit)}
                            onSave={saveCategory}
                            gateTaskConfig={firstCalculationGateTaskConfig}
                            disabled={!currentUserCanEditMeasure}
                            effectCategoryFields={effectCategoryFields}
                            effectCategoryValuesInUse={getEffectCategoryValuesInUse(effectCategories.data, categoryToEdit)}
                            allEffectCategoryValues={allEffectCategoryValues}
                            showCategoryFields
                            showCalculationFields={false}
                            defaultEffectType={defaultEffectType}
                            effectTypes={getEffectCategoryEffectTypesInUse(effectCategories.data, categoryToEdit)}
                            generations={generations}
                            withBadge={false}
                            measure={measure}
                            currencyIdsInUse={getEffectCategoryCurrenciesInUse(effectCategories.data, categoryToEdit)}
                        />
                    )}
                    <DeleteDialog
                        item={`effect_type_${
                            effectCategories.data.find(({ id }) => categoryToDelete === id)?.effectType ?? defaultEffectType
                        }`}
                        translate={translate}
                        open={categoryToDelete != null}
                        onClose={() => setCategoryToDelete(null)}
                        onDelete={removeEffectCategory}
                    />
                    {categoryToShowHistory !== null && (
                        <CurrencyContextProvider
                            currency={effectCategories.data.find((ec) => ec.id === categoryToShowHistory)?.currency ?? processCurrency}
                        >
                            <CalculationHistoryDialog
                                open={true}
                                categoryId={categoryToShowHistory}
                                translate={translate}
                                onClose={() => setCategoryToShowHistory(null)}
                                gateTaskConfigs={measure.measureConfig.gateTaskConfigs}
                                generations={
                                    allGenerations.data?.filter(({ effectCategoryId }) => effectCategoryId === categoryToShowHistory) ?? []
                                }
                                gateTasks={measure.gateTasks}
                            />
                        </CurrencyContextProvider>
                    )}
                    <Box py={1} px={3}>
                        <ExtendedCalculationHeader
                            granularity={measure.calculationGranularity}
                            updateGranularity={updateGranularity}
                            translate={translate}
                            isFullscreen={isFullscreen}
                            toggleFullscreen={toggleFullscreen}
                            fiscalYearStart={fiscalYearStart}
                            start={start}
                            onStartChanged={setCustomStart}
                            end={end}
                            onEndChanged={setCustomEnd}
                            maxStart={effectStart ?? undefined}
                            minEnd={effectEnd ?? undefined}
                            disabled={!currentUserCanEditMeasure || !hasEffectCategories}
                            calculationIdentifiers={calculationIdentifiers}
                            visibleCalculationIdentifiers={visibleCalculationIdentifiers}
                            updateVisibleCalculationIdentifiers={updateVisibleCalculationIdentifiers}
                        />
                    </Box>

                    <Divider />

                    {hasEffectCategories && allGenerations.isSuccess && effects.isSuccess ? (
                        <>
                            <CalculationTable
                                data={effects.data}
                                translate={translate}
                                lang={lang}
                                measure={measure}
                                effectCategoryAttributes={effectCategoryFields}
                                granularity={measure.calculationGranularity}
                                fiscalYearStart={fiscalYearStart}
                                start={start}
                                end={end}
                                disabled={!currentUserCanEditMeasure}
                                effectCategories={effectCategories.data}
                                allGenerations={allGenerations.data}
                                copyGeneration={copyFields}
                                updateGeneration={updateGeneration}
                                updateEffects={updateEffects}
                                deleteEffects={deleteEffects}
                                visibleCalculationIdentifiers={visibleCalculationIdentifiers}
                                onEffectCategoryDelete={setCategoryToDelete}
                                onEffectCategoryEdit={setCategoryToEdit}
                                onAddTimeslice={onAddTimeslice}
                                onEffectCategoryHistory={setCategoryToShowHistory}
                                lockedCalculationIdentifiers={lockedCalculationIdentifiers}
                            />
                            {lockedCalculationIdentifiers.length === 0 ? <Divider /> : null}
                        </>
                    ) : (
                        <Grid container justifyContent="center" pt={3}>
                            <Grid item>
                                <NoDataComponent />
                            </Grid>
                        </Grid>
                    )}
                    {firstCalculationGateTaskConfig != null && lockedCalculationIdentifiers.length === 0 && (
                        <Grid container justifyContent={hasEffectCategories ? "space-between" : "center"} alignItems="center" py={2} px={3}>
                            <Grid item>
                                <AddEffectCategoryButtons
                                    onAdd={addCategory}
                                    disabled={!currentUserCanEditMeasure}
                                    translate={translate}
                                    allValues={allEffectCategoryValues}
                                    effectCategories={effectCategories.data}
                                />
                            </Grid>
                        </Grid>
                    )}
                </div>
            </OptionalDialog>
        </Paper>
    );
};

export default React.memo(ExtendedCalculation);
